body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.option-li {
  position: relative;
  list-style: none;
  /* Adjust the spacing between list items */
}

.option-li::before {
  
  content: counter(item, upper-alpha);
  counter-increment: item;
  width: 100;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  /* Adjust the distance between the content and the letter box */
}

.options div {
  
  padding-left: 30px
}

.options {
  list-style: none;
  counter-reset: item;
}
